<template>
  <div class="maininiciocorreo">
    <div class="container-modal-registro">
      <div class="title-c">
        <h1>Inicia Sesión</h1>
      </div>
      &nbsp;
      <form class="container-main-form" name="form" id="form" v-on:submit.prevent="submitLogin()">
        <div class="container-label">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"> <box-icon name="envelope"></box-icon></span>

            <input type="email" class="form-control" placeholder="Ingresa Un Correo Electrónico" aria-label="Username" aria-describedby="basic-addon1" v-model="state.email" />
          </div>
        </div>
        <div class="container-label">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"><box-icon name="key"></box-icon></span>
            <input type="password" class="form-control" placeholder="Ingresa Una Contraseña" aria-label="Contraseña" aria-describedby="basic-addon1" v-model="state.password" />
          </div>
        </div>

        <div class="button-session">
          <button type="submit" class="btn btn-outline-primary" :disabled="response.loading">
            Iniciar Sesión
            <div v-show="response.loading" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; margin: 3px">
          <p class="text-primary" style="cursor: pointer" data-bs-toggle="modal" data-bs-target="#exampleModal">¿Olvidaste tu contraseña?</p>
        </div>
        <!-- Modal Reset Password-->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Reestablecer contraseña</h1>
                <button id="modalClose" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-label">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><box-icon name="envelope"></box-icon></span>
                    <input type="email" class="form-control" placeholder="Correo Electrónico" aria-label="Username" aria-describedby="basic-addon1" v-model="email_password" />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="button" class="btn btn-primary" v-on:click="recovery()" :disabled="response.loading || email_password.length < 8">
                  Solicitar
                  <div v-show="response.loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="container-redirect-register">
          <div>
            <span style="color: #f5782d; font-size: 18px; font-weight: bolder; font-family: Poppins">¿No tienes una cuenta?</span>
          </div>
          <div>
            <router-link class="text-decoration-none" to="/signin">
              <span class="link" style="color: #517aa8; font-size: 18px; font-family: Poppins">Regístrate</span>
            </router-link>
          </div>
        </div>
        &nbsp; &nbsp;
      </form>
    </div>
  </div>
</template>
<script>
import { computed, reactive } from "vue";
import { UserService } from "@/services";
import useValidate from "@vuelidate/core";
import { minLength, helpers, required, email } from "@vuelidate/validators";

export default {
  data: () => ({
    email_password: "",
    response: {
      loading: false,
    },
  }),
  setup() {
    const state = reactive({
      email: "",
      password: "",
    });
    const rules = computed(() => {
      return {
        email: {
          require: helpers.withMessage("Por favor, ingrese un correo.", required),
          email: helpers.withMessage("Por favor, ingrese un correo válido.", email),
        },
        password: {
          require: helpers.withMessage("Por favor, ingrese una contraseña.", required),
          minLength: helpers.withMessage("Ingrese una contraseña válida de al menos 4 dígitos.", minLength(4)),
        },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      v$,
    };
  },
  mounted() {
    let dataUser = JSON.parse(localStorage.getItem("user") || null);
    if (dataUser != null) {
      if (dataUser.roles[0] === "student") {
        window.location.href = "/profile";
      } else if (dataUser.roles[0] === "company") {
        window.location.href = "/empresa/perfil";
      } else if (dataUser.roles[0] === "admin") {
        window.location.href = "/admin";
      }
    }
  },
  methods: {
    async login() {
      this.response.loading = true;
      const data = { email: this.state.email, password: this.state.password };
      await UserService.loginEmail(data)
        .then((response) => {
          const user = response.data.data;
          console.log(user);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("email", user.email);
          if (user.register_complete) {
            if (user.roles[0] === "company") {
              window.location.href = "/empresa/perfil";
            } else if (user.roles[0] === "student") {
              window.location.href = "/profile";
            } else if (user.roles[0] === "admin") {
              window.location.href = "/admin";
            }
          } else if (!user.register_complete && user.formSelected === "") {
            this.alertUse("Tu Registro Esta Incompleto", "error");
            window.location.href = "/selectuser";
            return;
          } else if (!user.register_complete) {
            this.alertUse("Continua con tu registro", "warning");
            if (user.formSelected === "Preuniversitario") {
              window.location.href = "/preuniversitario";
            } else if (user.formSelected === "Universitario") {
              window.location.href = "/universitario";
            } else if (user.formSelected === "ExUniversitario") {
              window.location.href = "/exuniversitario";
            }
          }
          this.response.loading = false;
        })
        .catch((error) => {
          this.response.loading = false;
          this.$swal({
            position: "top-center",
            icon: "error",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    submitLogin() {
      this.v$.$validate();
      if (!this.v$.$error) {
        setTimeout(this.login(), 4000);
      }
    },
    alertUse(title, iconAlert) {
      this.$swal({
        position: "top-center",
        icon: iconAlert,
        text: title,
        showConfirmButton: false,
        timer: 4000,
      });
    },
    async recovery() {
      this.response.loading = true;
      const data = { email: this.email_password };
      await UserService.forgotPassword(data)
        .then((response) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            text: response.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          this.$swal({
            position: "top-center",
            icon: "error",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .finally(() => {
          this.email_password = "";
          this.response.loading = false;
          const modaleRecovery = document.getElementById("modalClose");
          modaleRecovery.click();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/loginemail.scss";
</style>
